/******
*******
vfdsa
*******
******/

/******
*******
VARIABLES
*******
******/

:root {
  /* Colours */
  --blue: #0381ef;
  --textDark: #1c1c1c;
  --textLight: #677587;
  --backgroundGrey: #f6f9fc;
  --border: #dfdfe1;
  --green: rgb(68, 200, 129);
  --white: #fff;

  --darkGrey: #1c1c1c;
  --lightGrey: #677587;
  --alphaGrey: rgba(72, 94, 144, 0.17);
  --alphaBlue: rgba(4, 112, 96, 0.1);
  --alphaBlueSelected: rgba(5, 112, 96, 0.7);
  --stroke: rgba(72, 94, 144, 0.16);
  --strokeDark: rgba(72, 94, 144, 0.3);
  --strokeHover: rgba(72, 94, 144, 0.8);
  --strokeActive: rgba(72, 94, 144, 0.5);
  --bg: #f6f9fc /*#fbf8f2*/;
  --Lbg: #fefcf7;
  --greenAlpha: rgba(68, 200, 129, 0.2);
  --amber: #f8af69;
  --yellow: rgb(255, 240, 204);
  --red: #ff5757;
  --teal: rgb(92, 194, 238);
  --beige: #f5eee2;

  /* Spacers */
  --sXS: 5px;
  --sS: 10px;
  --sM: 20px;
  --sL: 30px;
  --sXL: 60px;
  --sXXL: 90px;
  --sXXXL: 120px;
  --sr: 5px;

  /* Type manipulators */
  --fBold: 700;
  --fSemibold: 600;
}

/******
*******
GLOBAL STYLING
*******
******/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: var(--bg);
  margin-top: 60px;
  width: 100%;
  height: calc(100% - 60px);
}

.window {
  height: 100%;
  width: 100%;
}

/******
*******
TYPE
*******
******/

h1 {
  font-size: 4.15em;
  font-weight: 700;
  letter-spacing: -1.5px;
  margin: 0;
  line-height: 1.1;
}

h2 {
  font-size: 2.6em;
  letter-spacing: -0.8px;
  margin: 0;
  line-height: 1.2;
}

h3 {
  font-size: 1.6em;
  letter-spacing: -0.7px;
  margin: 0;
  line-height: 1.35;
}

h4 {
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;
}

h5 {
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 0px;
  margin: 0;
}

p {
  color: #444;
  font-size: 16px;
  line-height: 1.7;
}

p.large {
  font-size: 20px;
  line-height: 1.5;
  color: var(--lightGrey);
}

p.small {
  font-size: 14px;
}

p.error {
  color: var(--red);
}

a {
  text-decoration: none;
  color: var(--darkGrey);
}

@media only screen and (max-width: 769px) {
  body {
    font-size: 12px;
  }
}

.gradientCircle {
  height: 45px;
  width: 45px;
  background: #0381ef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 21px;
}

.truncate {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* TYPE MANIPULATORS */

.taC {
  text-align: center;
}

.taL {
  text-align: left;
}

.taR {
  text-align: right;
}

.cB {
  color: var(--blue) !important;
}

.cAB {
  color: var(--alphaBlue) !important;
}

.cDG {
  color: var(--darkGrey) !important;
}

.cW {
  color: var(--white) !important;
}

.cLG {
  color: var(--lightGrey) !important;
}

.cAG {
  color: var(--alphaGrey) !important;
}

.normal {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

/******
*******
FOOTER
*******
******/

footer {
  background: var(--white);
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.footer-container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100px;
  align-items: center;
}

.footer-logo {
  display: flex;
  align-self: center;
  flex-grow: 1;
}

.footer-list-item {
  margin-right: var(--sM);
}

.footer-list-item:last-child {
  opacity: 0.4;
}

.footer-link {
  padding: 8px 16px;
  color: var(--textDark);
  background-color: transparent;
  border-radius: 5px;
  transition: background 100ms ease-out 0s;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.footer-link:hover {
  background-color: var(--backgroundGrey);
}

@media only screen and (max-width: 769px) {
  .footer-container {
    display: block;
    height: auto;
  }

  .footer-logo {
    display: block;
    padding: 30px 0 0;
    margin: 0 auto;
    text-align: center;
  }

  ul {
    margin: 0 auto;
    text-align: center;
  }

  .footer-list-item {
    display: block;
    padding: 10px 0 20px;
    margin-right: 0;
  }

  .footer-list-item:last-child {
    display: block;
    padding: 10px 0 50px;
  }

  .footer-link {
    text-align: center;
    margin: 0 auto;
  }
}

/******
*******
LANDING
*******
******/

.landing-hero {
  padding-top: var(--sXXL);
}

.landing-image {
  width: 100%;
}

.landing-hero-headline {
  font-weight: var(--fBold);
  padding-bottom: var(--sM);
  text-align: center;
  margin: 0 auto;
  max-width: 740px;
  color: var(--textDark);
}

.landing-hero-description {
  text-align: center;
  margin: 0 auto;
  max-width: 740px;
  font-size: 20px;
  padding-bottom: var(--sL);
  color: var(--textLight);
}

.landing-hero-actions {
  text-align: center;
  margin: 0 auto;
}

.landing-hero-actions-primary,
.landing-hero-actions-secondary {
  padding: 6px 35px !important;
  height: auto !important;
}

.landing-hero-actions-primary {
  margin-right: var(--sS);
}

.landing-explainer {
  padding-top: var(--sXXXL);
}

.landing-explainer-header {
  font-weight: var(--fBold);
  padding-bottom: var(--sS);
  max-width: 740px;
  text-align: center;
  margin: 0 auto;
  color: var(--textDark);
}

.landing-explainer-description {
  padding-bottom: var(--sXL);
  text-align: center;
  font-size: 20px;
  max-width: 740px;
  margin: 0 auto;
  color: var(--textLight);
}

.landing-explainer-grid {
  display: flex;
}

.landing-explainer-column {
  width: 50%;
}

.landing-explainer-column:first-child {
  padding-right: var(--sM);
}

.landing-explainer-column:last-child {
  padding-left: var(--sM);
}

.landing-explainer-module {
  height: 100%;
  width: 100%;
  background: var(--backgroundGrey);
  border-radius: 25px;
  overflow: hidden;
}

.landing-explainer-module-content {
  padding: var(--sL);
}

.landing-explainer-module-strapline {
  font-weight: var(--fSemibold);
  margin-bottom: var(--sS);
  color: var(--textLight);
}

.landing-explainer-module-header {
  font-weight: var(--fSemibold);
  color: var(--textLight);
}

.landing-explainer-module-header-strong {
  font-weight: var(--fBold);
  color: var(--textDark);
}

.landing-pricing-grid {
  padding-top: var(--sL);
  padding-bottom: var(--sXXL);
  max-width: 650px;
  margin: 0 auto;
  display: flex;
}

.landing-pricing-card {
  border: 1px solid var(--border);
  border-radius: 5px;
  text-align: center;
  width: 50%;
}

.landing-pricing-card-content {
  padding: var(--sM) var(--sM) var(--sL);
  border-bottom: 1px solid var(--border);
}

.landing-pricing-card:first-child {
  margin-right: var(--sS);
}

.landing-pricing-card:last-child {
  margin-left: var(--sS);
}

.landing-pricing-card-plan {
  font-size: 20px;
  color: var(--textDark);
  font-weight: var(--fSemibold);
  margin-bottom: var(--sXS);
  margin-top: var(--sS);
}

.landing-pricing-card-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-pricing-card-price {
  font-weight: var(--fBold);
  margin-bottom: var(--sXS);
}

.landing-pricing-card-price-cadence {
  margin-left: var(--sXS);
}

.landing-pricing-card-description {
  font-size: 14px;
  margin-bottom: var(--sL);
}

.landing-pricing-card-included {
  display: flex;
  align-items: center;
  margin-bottom: var(--sXS);
}

.landing-pricing-card-included-copy {
  text-align: left;
  margin-left: var(--sXS);
}

.landing-pricing-card-included-copy-strong {
  font-weight: var(--fBold);
}

.landing-pricing-card-cta {
  background: var(--backgroundGrey);
  padding: var(--sM);
}

.landing-pricing-card-button {
  width: 100%;
}

.landing-pricing-card-subcopy {
  font-size: 14px;
  opacity: 0.6;
  margin-top: var(--sS);
}

.landing-signup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-signup-input {
  flex-grow: 1;
  max-width: 380px;
}

.landing-signup-cta {
  margin-left: var(--sXS);
}

@media only screen and (max-width: 769px) {
  .landing-hero-actions-primary,
  .landing-hero-actions-secondary {
    display: flex;
    justify-content: center;
  }

  .landing-hero-actions-primary {
    margin-right: 0;
    margin-bottom: var(--sS);
  }

  .landing-explainer-grid {
    display: block;
  }

  .landing-explainer-column {
    width: 100%;
  }

  .landing-explainer-column:first-child {
    padding-right: 0;
    padding-bottom: var(--sL);
  }

  .landing-explainer-column:last-child {
    padding-left: 0;
  }

  .landing-pricing-grid {
    display: block;
  }

  .landing-pricing-card {
    width: 100%;
  }

  .landing-pricing-card:first-child {
    margin-right: 0;
    margin-bottom: var(--sL);
  }

  .landing-pricing-card:last-child {
    margin-left: 0;
  }

  .landing-signup {
    display: block;
    width: 100%;
  }

  .landing-signup-input {
    margin-bottom: var(--sS);
    width: 100%;
    max-width: none;
  }

  .landing-signup-cta {
    margin-left: 0;
  }
}

/******
*******
LANDING
*******
******/

.projects-header {
  padding-top: var(--sXL);
  background: var(--white);
  border-bottom: 1px solid var(--border);
}

.projects-header-content {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px var(--sXL);
  display: flex;
  align-items: flex-end;
}

.projects-header-content-copy {
  flex-grow: 1;
}

.projects-header-cta {
  margin-right: var(--sM);
}

.projects-container {
  padding-top: var(--sM);
}

.projects-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects-empty {
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: var(--sM);
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.projects-empty-heading {
  font-weight: var(--fSemibold);
  margin-top: var(--sXXL);
}

.projects-empty-description {
  margin-top: var(--sS);
}

.projects-empty-cta {
  margin-bottom: var(--sXXL);
  margin-top: var(--sM);
}

.projects-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 49%;
  background: var(--white);
  margin-bottom: var(--sM);
}

@media only screen and (max-width: 769px) {
  .projects-header-content {
    display: block;
    padding: 0 20px var(--sL);
  }

  .projects-header-content-copy {
    margin-bottom: var(--sM);
  }

  .projects-header-cta {
    margin-bottom: var(--sS);
  }

  .projects-grid {
    display: block;
  }

  .projects-card {
    width: 100%;
  }

  .projects-card-cta {
    width: auto !important;
  }
}

/******
*******
*******
*******
*******
*******
*******
xxxxxxxxxxxxxxx
*******
*******
*******
*******
*******
*******
******/

.backgroundWhite {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

/* STYLES */

.border {
  border: 1px solid var(--stroke);
}

.borderDash {
  border: 1px dashed var(--stroke);
}

.borderR {
  border-right: 1px solid var(--stroke);
}

.borderT {
  border-top: 1px solid var(--stroke);
}

.borderB {
  border-bottom: 1px solid var(--stroke);
}

.bgG {
  background-color: #f6f9fc;
}

.bgDG {
  background-color: var(--darkGrey);
}

.bgLG {
  background-color: var(--Lbg);
}

.bgAB {
  background-color: var(--alphaBlue);
}

.bgW {
  background-color: var(--white);
}

.br {
  border-radius: 5px;
}

.brB {
  border-radius: 0 0 5px 5px;
}

.brBig {
  border-radius: 25px;
}

.pXS {
  padding: var(--sXS);
}

.ptXS {
  padding-top: var(--sXS);
}

.prXS {
  padding-right: var(--sXS);
}

.pbXS {
  padding-bottom: var(--sXS);
}

.plXS {
  padding-left: var(--sXS);
}

.pS {
  padding: var(--sS);
}

.ptS {
  padding-top: var(--sS);
}

.prS {
  padding-right: var(--sS);
}

.pbS {
  padding-bottom: var(--sS);
}

.plS {
  padding-left: var(--sS);
}

.pM {
  padding: var(--sM);
}

.ptM {
  padding-top: var(--sM);
}

.prM {
  padding-right: var(--sM);
}

.pbM {
  padding-bottom: var(--sM);
}

.plM {
  padding-left: var(--sM);
}

.pL {
  padding: var(--sL);
}

.ptL {
  padding-top: var(--sL);
}

.prL {
  padding-right: var(--sL);
}

.pbL {
  padding-bottom: var(--sL);
}

.plL {
  padding-left: var(--sL);
}

.pXL {
  padding: var(--sXL);
}

.ptXL {
  padding-top: var(--sXL);
}

.prXL {
  padding-right: var(--sXL);
}

.pbXL {
  padding-bottom: var(--sXL);
}

.plXL {
  padding-left: var(--sXL);
}

.pXXL {
  padding: var(--sXXL);
}

.ptXXL {
  padding-top: var(--sXXL);
}

.prXXL {
  padding-right: var(--sXXL);
}

.pbXXL {
  padding-bottom: var(--sXXL);
}

.plXXL {
  padding-left: var(--sXXL);
}

.pXXXL {
  padding: var(--sXXXL);
}

.ptXXXL {
  padding-top: var(--sXXXL);
}

.prXXXL {
  padding-right: var(--sXXXL);
}

.pbXXXL {
  padding-bottom: var(--sXXXL);
}

.plXXXL {
  padding-left: var(--sXXXL);
}

.mXS {
  margin: var(--sXS);
}

.mtXS {
  margin-top: var(--sXS);
}

.mrXS {
  margin-right: var(--sXS);
}

.mbXS {
  margin-bottom: var(--sXS);
}

.mlXS {
  margin-left: var(--sXS);
}

.mS {
  margin: var(--sS);
}

.mtS {
  margin-top: var(--sS);
}

.mrS {
  margin-right: var(--sS);
}

.mbS {
  margin-bottom: var(--sS);
}

.mlS {
  margin-left: var(--sS);
}

.mM {
  margin: var(--sM);
}

.mtM {
  margin-top: var(--sM);
}

.mrM {
  margin-right: var(--sM);
}

.mbM {
  margin-bottom: var(--sM);
}

.mlM {
  margin-left: var(--sM);
}

.mL {
  margin: var(--sL);
}

.mtL {
  margin-top: var(--sL);
}

.mrL {
  margin-right: var(--sL);
}

.mbL {
  margin-bottom: var(--sL);
}

.mlL {
  margin-left: var(--sL);
}

.mXL {
  margin: var(--sXL);
}

.mtXL {
  margin-top: var(--sXL);
}

.mrXL {
  margin-right: var(--sXL);
}

.mbXL {
  margin-bottom: var(--sXL);
}

.mlXL {
  margin-left: var(--sXL);
}

.mXXL {
  margin: var(--sXXL);
}

.mtXXL {
  margin-top: var(--sXXL);
}

.mrXXL {
  margin-right: var(--sXXL);
}

.mbXXL {
  margin-bottom: var(--sXXL);
}

.mlXXL {
  margin-left: var(--sXXL);
}

.mXXXL {
  margin: var(--sXXXL);
}

.mtXXXL {
  margin-top: var(--sXXXL);
}

.mrXXXL {
  margin-right: var(--sXXXL);
}

.mbXXXL {
  margin-bottom: var(--sXXXL);
}

.mlXXXL {
  margin-left: var(--sXXXL);
}

.noMB:last-child {
  margin-bottom: 0px !important;
}

.cPointer {
  cursor: pointer;
}

.gHover {
  background: var(--white);
  border: 1px solid var(--stroke);
}

.gHover:hover {
  background: rgb(242, 245, 247);
}

/******
*******
*******
*******
*******
*******
*******
03. NAVIGATION
*******
*******
*******
*******
*******
*******
******/

header {
  z-index: 299;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid var(--stroke);
  position: fixed;
}

.subHeader {
  z-index: 199;
  backdrop-filter: blur(5px);
  width: 100%;
  position: fixed;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid var(--stroke);
}

.globalBack {
  height: 60px;
}

.mobileNav {
  display: none;
}

.headerWrapper {
  height: 60px;
}

ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

nav {
  display: flex;
  align-self: center;
  text-align: right;
}

.hb {
  cursor: pointer;
  padding: 8px 16px;
  color: #1c1c1c;
  background-color: transparent;
  border-radius: 5px;
  transition: background 100ms ease-out 0s;
  font-size: 16px;
}

.hb:hover {
  background: rgb(242, 245, 247);
  text-decoration: none !important;
}

.hamburgerMenu {
  width: 100%;
  height: calc(100% - 60px);
  z-index: 999;
  background-color: var(--white);
  position: fixed;
  left: 0;
  top: 60px;
}

.popOver {
  background-color: #fff;
  z-index: 999;
  top: 35px;
  right: 0;
  position: absolute;
  align-content: flex-end;
  min-width: 240px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.feedback {
  min-width: 340px;
  top: 35px;
}

.more {
  top: 15px;
}

.image {
  top: 15px;
  left: 0;
  max-width: 500px;
}

.actions {
  top: 20px;
}

.logo {
  cursor: pointer;
  width: 125px;
  padding-top: 5px;
}

.navLink {
  padding: 8px 16px;
  color: #1c1c1c;
  background-color: transparent;
  border-radius: 5px;
  transition: background 100ms ease-out 0s;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.navLink:hover {
  background: var(--bg);
  text-decoration: none !important;
}

.navLink.active {
  background: #fbf8f2;
}

.textLink {
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 5px;
  transition: background 100ms ease-out 0s;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  width: auto !important;
}

.textLink:hover {
  background: var(--alphaBlue);
  text-decoration: none !important;
}

.navBrand {
  display: flex;
  align-self: center;
  flex-grow: 1;
}

.navActions {
  display: flex;
  align-self: center;
}

.navActions:hover {
  text-decoration: none;
}

/******
*******
*******
*******
*******
*******
*******
04. LAYOUT
*******
*******
*******
*******
*******
*******
******/

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.innerContainer {
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.dFlex {
  display: flex;
}

.imageContainer {
  height: 100%;
  position: absolute;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  padding: 0;
}

.imageContainerCentre {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  padding: 0;
}

.flexGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.jcCenter {
  justify-content: center;
}

.jcSpaceBetween {
  justify-content: space-between;
}

.aiFlexStart {
  align-items: flex-start;
}

.aiFlexEnd {
  align-items: flex-end;
}

.aiStretch {
  align-items: stretch !important;
}

.jcFlexEnd {
  justify-content: flex-end;
}

.aiCenter {
  align-items: center;
}

.aiFlexEnd {
  align-items: flex-end !important;
}

/* LAYOUT MANIPULATORS */

.bg-cols::before {
  background-color: #fff;
  content: "";
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;
  border-right: 1px solid var(--stroke);
}

.c {
  margin: 0 auto;
}

.z10 {
  z-index: 10;
}

.vCentre {
  top: 50%;
  left: 32%;
  transform: translateY(-50%);
  position: absolute;
}

.dB {
  display: block;
}

.hoverOpacity {
  opacity: 1;
  transition: all 0.2s ease;
}

.hoverOpacity:hover {
  opacity: 0.7;
}

.hoverBackground {
  background: transparent;
  transition: all 0.2s ease;
}

.hoverBackground:hover {
  background: #f6f9fc;
}

.dIB {
  display: inline-block;
  vertical-align: top;
}

.mw470 {
  max-width: 470px;
}

.mw650 {
  max-width: 650px;
}

.mw900 {
  max-width: 900px;
}

.mw75p {
  max-width: 75%;
}

.w32p {
  width: 32%;
}

.w33p {
  width: 33%;
}
.w30p {
  width: 30%;
}
.w70p {
  width: 70%;
}

.w80p {
  width: 80%;
}

.w50p {
  width: 50%;
}

.overflow {
  overflow-y: hidden;
}

.w40p {
  width: 40%;
}

.w25p {
  width: 25%;
}

.w20p {
  width: 20%;
}

.w15p {
  width: 15%;
}

.w100p {
  width: 100%;
}

.w130 {
  width: 130px;
}

.h100p {
  height: 100% !important;
}

.fr {
  float: right;
}

.fg1 {
  flex-grow: 1;
}

.posRel {
  position: relative;
}

.posFix {
  position: fixed;
}

.posAbs {
  position: absolute;
}

.o2 {
  opacity: 0.2;
}

.o4 {
  opacity: 0.4;
}

.o6 {
  opacity: 0.6;
}

/******
*******
*******
*******
*******
*******
*******
05. BUTTONS & CONTROLS
*******
*******
*******
*******
*******
*******
******/

button {
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  text-align: center;
}

@media only screen and (max-width: 769px) {
  button {
    width: 100%;
  }
}

button.primary {
  background-color: #1c1c1c;
  line-height: 38px;
  font-weight: 500;
  color: var(--white);
  padding: 0px 25px;
  border: 1px solid #1c1c1c;
  font-size: 15px;
  height: 40px;
  border-radius: 5px;
}

button.primary.more {
  padding: 0px 15px !important;
}

button.primary:hover {
  background-color: transparent;
  color: #1c1c1c;
}

button.secondary {
  background-color: var(--white);
  line-height: 38px;
  font-weight: 500;
  color: #666;
  padding: 0px 25px;
  border: 1px solid #eaeaea;
  font-size: 15px;
  height: 40px;
  border-radius: 5px;
}

button.secondary:hover {
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
}

button.tertiary {
  background-color: var(--white);
  font-weight: 500;
  color: #666;
  padding: 6px 12px;
  border: 1px solid #eaeaea;
  font-size: 15px;
  height: 32px;
  border-radius: 5px;
  min-width: 70px;
}

button.tertiary:hover {
  border: 1px solid #1c1c1c;
  color: #1c1c1c;
}

button.destructive {
  background-color: #f84f77;
  line-height: 38px;
  font-weight: 500;
  color: var(--white);
  padding: 0px 25px;
  border: 1px solid #f84f77;
  font-size: 15px;
  height: 40px;
  border-radius: 5px;
}

button.destructive:hover {
  background-color: transparent;
  color: #f84f77;
}

button.inactive {
  opacity: 0.2;
}

button.toggle {
  padding: 10px 20px;
  border: 1px solid var(--stroke);
  background-color: transparent;
  color: var(--darkGrey);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  box-shadow: none;
}

button.toggle:hover {
  border: 1px solid var(--strokeHover);
}

button.tag {
  display: inline-block !important;
  color: var(--dg) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  height: 24px !important;
  border-radius: 4px !important;
  padding: 0 8px !important;
  line-height: 24px !important;
  background-color: var(--bg);
  border: 1px solid var(--stroke) !important;
}

.pill {
  background-color: var(--alphaBlue);
  color: var(--blue);
  opacity: 0.7;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  box-shadow: none;
}

.pill:hover {
  opacity: 1;
}

button:disabled {
  opacity: 0.2;
}

.iconBoundary {
  border: 1px solid var(--stroke);
  transition: 0.2s ease;
  opacity: 0.7;
}

.iconBoundary:hover {
  opacity: 1;
}

.iconBoundary:active {
  transform: scale(0.93);
}

/******
*******
*******
*******
*******
*******
*******
06. FORM ELEMENTS
*******
*******
*******
*******
*******
*******
******/

::placeholder {
  color: #777;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  height: 45px;
  line-height: 1.5;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--stroke);
  vertical-align: top;
  transition: all 0.2s ease;
  background-color: var(--white);
}

select {
  color: #666;
}

.inputLink {
  font-size: 14px;
  border-radius: 5px;
  background-color: var(--bg);
  padding: 12px 18px;
  cursor: pointer;
}

input,
textarea {
  padding: 10px;
}

textarea.large {
  height: 60px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: 1px solid var(--strokeActive);
}

input:hover,
textarea:hover,
select:hover {
  border: 1px solid var(--strokeHover);
}

input.ghostInput {
  border: none;
  font-size: 28px;
  margin: 0;
  letter-spacing: -0.4px;
  line-height: 1.7;
  padding: 0 0 0 10px;
  font-weight: 700;
  border-bottom: 1px solid var(--white);
  border-radius: 0;
  transition: all 0.2s ease;
  background: transparent;
}

input.ghostInput:hover {
  border-bottom: 1px solid var(--stroke);
}

.inputList {
  top: -32px;
  left: 8px;
  position: absolute;
}

select {
  padding: 11px 20px;
  cursor: pointer;
}

.autopredictContainer {
  top: 0;
  max-height: 175px;
  overflow: scroll;
  z-index: 30;
}

.autopredictItem {
  background-color: white;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.autopredictItem:hover {
  background-color: #f5f5f5;
}

hr {
  margin: 0;
  padding: 0;
  border: 1px solid var(--stroke);
}

label {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin: 0;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #f5f5f5;
  color: #1c1c1c;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/******
*******
*******
*******
*******
*******
*******
07. GLOBAL UI
*******
*******
*******
*******
*******
*******
******/

.backdrop {
  z-index: 399;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.notification {
  background-color: var(--yellow);
}

.modal {
  z-index: 500;
  background-color: #fff;
  min-width: 335px;
  width: 40%;
  transition: all 0.3s ease-out;
  margin: 200px auto 0;
}

.avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #eee;
  cursor: pointer;
}

/******
*******
*******
*******
*******
*******
*******
08. MARKETING UI
*******
*******
*******
*******
*******
*******
******/

.line {
  height: 100px;
  width: 1px;
  background-color: var(--stroke);
}

.howItWorks {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px 0px;
}

.landingContentOuter {
  height: calc(100% - 120px);
  width: 100%;
  overflow: hidden;
}

.landingContentInner {
  display: flex;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
  position: relative;
}

.landingContentCopyLeft {
  width: 65%;
}

.landingContentCopyRight {
  width: 50%;
  padding-left: 60px;
  left: 50%;
  position: absolute;
}

.landingContentImageRight {
  height: 100%;
  position: absolute;
  left: 50%;
}

.landingContentImageLeft {
  height: 100%;
  right: 50%;
  position: absolute;
}

.landingContentHow {
  background-color: #f5f5f5;
}

/******
*******
*******
*******
*******
*******
*******
09. APP UI
*******
*******
*******
*******
*******
*******
******/

.appContainer {
  height: calc(100% - 120px);
  padding-top: 60px;
  z-index: 3;
}

.appPanelContainer {
  min-height: 100% !important;
  background-color: var(--white) !important;
  border-right: 1px solid var(--stroke);
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 16px;
}

.appPreview {
  left: 30%;
  position: absolute;
}

.lineDivider {
  height: 40px;
  width: 1px;
  background-color: var(--stroke);
}

.projectImage {
  height: 65px;
  width: 65px;
  border-radius: 10px;
  background-color: #f5f5f5;
  transition: all 0.2s ease;
}

.projectImageSmall {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
  transition: all 0.2s ease;
}

.projectImage:hover,
.projectImageSmall:hover {
  opacity: 0.7;
}

.welcomeLegend {
  top: 80px;
  left: 0;
  padding: var(--sL);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.deleteButton {
  position: relative;
  right: 0;
}

.cardProject {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 49%;
}

.cardProjectDemo {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px 0px;
  border-radius: 8px;
  width: 100%;
}

.cardIssueDemo {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px 0px;
  border-radius: 8px;
  width: 100%;
}

.cardProjectTitle {
  font-weight: 600;
  font-size: 24px;
  transition: all 0.2s ease;
}

.cardProjectTitle:hover {
  color: #4e4e4e;
}

.test {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  border-radius: 5px;
}

.listItem {
  border-radius: 5px;
}

.listItem:hover {
  background-color: #fafafa;
}

.listItemControls {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.label {
  display: inline-block;
  color: var(--white) !important;
  font-size: 12px !important;
  font-weight: 700;
  height: 24px;
  border-radius: 4px;
  padding: 0 8px;
  line-height: 24px;
}

.label.product {
  background-color: #f7b955;
}

.label.important {
  background-color: #f84f77;
}

.cockpit {
  width: calc(35% - 10px);
  display: inline-block;
}

.output {
  width: 65%;
  display: inline-block;
}

.card {
  vertical-align: top;
  background-color: #fff;
  /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);*/
  border: 1px solid var(--stroke);
}

.dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.stat {
  border-right: 1px solid var(--stroke);
}
.stat:last-child {
  border-right: none;
}

.statArrowBoundary {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.issueStat {
  height: 50px;
  width: 50px;
}
/******
*******
*******
*******
*******
*******
*******
*******
*******
*******
*******
*******
*******
******/

/******
*******
*******
*******
*******
*******
*******
10. responsive
*******
*******
*******
*******
*******
*******
******/

.fiB {
  opacity: 0;
  animation: fade-in-bottom ease 0.5s forwards;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 600px) {
  .mobile {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */

  @page {
    margin: 0;
  }
  body {
    margin: 2cm;
  }
}
